<template>
  <Page title="myGymer-Wahl" color="info">
    <v-container class="mb-4">
      <v-row class="align-center justify-center">
        <SchoolClassPicker outlined text v-model="schoolClass" />

        <v-spacer />

        <v-text-field
          type="search"
          dense
          single-line
          hide-details=""
          v-model="search"
          label="Schüler:in oder Fach"
          append-icon="mdi-magnify"
          clearable
        />
      </v-row>
    </v-container>
    <v-card>
      <v-system-bar
        >myGymer-Wahl {{ schoolClass ? schoolClass.code : "" }}</v-system-bar
      >
      <v-data-table
        :items="itemsFiltered"
        :headers="headers"
        hide-default-footer
        :loading="loading"
        :items-per-page="-1"
        sort-by="student"
      >
        <template v-slot:item.student="{ item }">
          <PersonItem small :value="item.student" />
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            icon
            :to="{
              name: 'OptionalStudent',
              params: { id: item.student.id },
            }"
            ><v-icon>mdi-pen</v-icon></v-btn
          >
        </template>
        <template v-slot:item.status="{ item }">
          <v-simple-checkbox
            v-if="item.needsToConfirm"
            :value="item.hasConfirmed"
            disabled
          />
        </template>
        <template v-slot:item.currentTerm="{ item }">
          <v-tooltip
            top
            v-for="subject in item.chosenNow"
            :key="`${item.student.id}now${subject.id}`"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" color="info" small>{{
                subject.code
              }}</v-chip>
            </template>
            <span>{{ subject.description }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.nextTerm="{ item }">
          <v-tooltip
            top
            v-for="subject in item.chosenNext"
            :key="`${item.student.id}next${subject.id}`"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" color="success" small>{{
                subject.code
              }}</v-chip>
            </template>
            <span>{{ subject.description }}</span>
          </v-tooltip>
        </template>
        <template v-slot:header.currentTerm="{}">
          {{ data.currentTerm ? data.currentTerm.fullText : "" }}
        </template>
        <template v-slot:header.nextTerm="{}">
          {{ data.nextTerm ? data.nextTerm.fullText : "" }}
        </template>
        <template v-slot:footer
          ><TableFooter
            :items="itemsFiltered"
            label="Schüler:innen"
            labelSingular="Schüler:in"
          ></TableFooter
        ></template>
      </v-data-table>
      <router-view></router-view>
    </v-card>
  </Page>
</template>

<script>
import PersonItem from "@/components/PersonItem.vue";
import SchoolClassPicker from "@/components/SchoolClassPicker.vue";
import TableFooter from "common/components/TableFooter.vue";
import { comparePeople, searchPerson } from "common/utils/people.js";

export default {
  components: { PersonItem, SchoolClassPicker, TableFooter },
  data() {
    return {
      loading: false,
      search: "",
      data: { studentItems: [] },
      headers: [
        {
          text: "",
          value: "action",
        },
        {
          text: "Schüler*in",
          value: "student",
          sort: comparePeople,
        },
        {
          text: "bestätigt",
          value: "status",
        },

        {
          text: "currentTerm",
          value: "currentTerm",
        },
        {
          text: "nextTerm",
          value: "nextTerm",
        },
      ],
      schoolClass: null,
    };
  },
  watch: {
    schoolClass() {
      this.fetchData();
    },
  },
  computed: {
    itemsFiltered() {
      return this.data.studentItems.filter(
        (el) =>
          !this.search ||
          searchPerson(el.student, this.search) ||
          el.chosenNow.some((el) =>
            el.description.toLowerCase().includes(this.search.toLowerCase())
          ) ||
          el.chosenNext.some((el) =>
            el.description.toLowerCase().includes(this.search.toLowerCase())
          )
      );
    },
  },
  methods: {
    async fetchData() {
      if (!this.schoolClass) return;
      this.loading = true;
      this.data = await this.apiList({
        resource: "optional/choice",
        query: `schoolClass=${this.schoolClass.id}`,
      });
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
